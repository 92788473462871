import {
  LOGIN,
  IS_AUTH,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  LOGOUT,
  NOT_LOGGED,
} from "../types";

const initialState = {
  search: "",
  loading: false,
  userId: 0,
  firstName: "",
  lastName: "",
  email: "",
  profileId: 0,
  profile: "",
  country: "",
  password: "",
  isAuthenticated: false,
  authVerified: false,
  passwordResetDone: false,
  sessionClosed: false,
  error: "",
};

export function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
        passwordResetDone: false,
        sessionClosed: false,
      };
    case LOGIN_SUCCESSFUL:
      return {
        ...state,
        isAuthenticated: true,
        authVerified: true,
        loading: false,
        error: "",
        userId: action.payload.userId,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        profileId: action.payload.profileId,
        profile: action.payload.profile,
        country: action.payload.country,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case NOT_LOGGED:
      return {
        ...state,
        isAuthenticated: false,
        authVerified: true,
        success: "",
        error: "",
        loading: false,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        authVerified: true,
        sessionClosed: true,
        success: "",
        error: "",
        loading: false,
      };
    case IS_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
}
