import {
    faHome,
    faUsers,
    faBoxes,
    faStore,
    faBox,
    faInbox,
    faShip,
    faHandshake,
    faHandHolding,
    faFileExcel,
  } from "@fortawesome/free-solid-svg-icons";

  const LabMenu = [
    {
      name: "Dashboard",
      path: "/home",
      icon: faHome,
    },
    {
      name: "Customers",
      icon: faUsers,
      submenu: [
        {
          name: "Customers",
          path: "/customers",
        },
        {
          name: "Customer categories",
          path: "/customer-categories",
        },
      ],
    },
    {
      name: "Suppliers",
      icon: faBoxes,
      submenu: [
        {
          name: "Suppliers",
          path: "/suppliers",
        },
        {
          name: "Supplier categories",
          path: "/supplier-categories",
        },
      ],
    },
    {
      name: "Stores",
      path: "/stores",
      icon: faStore,
    },
    {
      name: "Products",
      icon: faBox,
      submenu: [
        {
          name: "Products",
          path: "/products",
        },
        {
          name: "Product categories",
          path: "/product-categories",
        },
        {
          name: "Measurement units",
          path: "/measurement-units",
        },
      ],
    },
    {
      name: "Receptions",
      path: "/receptions",
      icon: faInbox,
    },
    {
      name: "Shipments",
      path: "/shipments",
      icon: faShip,
    },
    {
      name: "Loans",
      path: "/loans",
      icon: faHandshake,
    },
    {
      name: "Returnments",
      path: "/returnments",
      icon: faHandHolding,
    },
    {
      name: "Reports",
      icon: faFileExcel,
      submenu: [
        {
          name: "Customers",
          path: "/customer/report",
        },
        {
          name: "Suppliers",
          path: "/supplier/report",
        },
        {
          name: "Loans",
          path: "/loan/report",
        },
        {
          name: "Stock",
          path: "/stock/report",
        },
        {
          name: "Movements",
          path: "/movement/report",
        },
      ],
    },
    /* {
      name: "Support",
      path: "/support",
      icon: faQuestion,
    }, */
  ]

  export default LabMenu;