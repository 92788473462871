// GENERAL
export const LOADING = "LOADING";
export const ERROR = "ERROR";
export const DATA_LOADED = "DATA_LOADED";

// AUTH
export const VALIDATION_FORM = "VALIDATION_FORM";
export const LOGIN = "LOGIN";
export const LOGIN_SUCCESSFUL = "LOGIN_SUCCESSFUL";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const IS_AUTH = "IS_AUTH";
export const ACTIVATION_KEY_ERROR = "ACTIVATION_KEY_ERROR";
export const ACTIVATION_KEY_SUCCESS = "ACTIVATION_KEY_SUCCESS";
export const PASSWORD_RESET_ERROR = "PASSWORD_RESET_ERROR";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const NOT_LOGGED = "NOT_LOGGED";

// PROFILES
export const GET_PROFILES_LIST = "GET_PROFILES_LIST";

// LANGUAGES
export const GET_ALL_LANGUAGES = "GET_ALL_LANGUAGES";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const LANGUAGES_LOADED = "LANGUAGES_LOADED";

// COUNTRIES
export const GET_COUNTRIES_LIST = "GET_COUNTRIES_LIST";

// PROCESSES
export const GET_PROCESS_LIST = "GET_PROCESS_LIST";
export const GET_PROCESS = "GET_PROCESS";
export const POST_PROCESS = "POST_PROCESS";
export const PUT_PROCESS = "PUT_PROCESS";
export const DELETE_PROCESS = "DELETE_PROCESS";
export const GET_ROOT_PROCESSES = "GET_ROOT_PROCESSES";
export const CLEAN_PROCESS = "CLEAN_PROCESS";
export const SET_PROCESS_ALIAS = "SET_PROCESS_ALIAS";
export const POST_CONTACT = "POST_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const POST_LINK = "POST_LINK";
export const DELETE_LINK = "DELETE_LINK";
export const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION";
export const POST_VIDEO = "POST_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const POST_DOCUMENT = "POST_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const POST_VISIT = "POST_VISIT";
export const POST_RATING = "POST_RATING";
export const GET_REPORT = "GET_REPORT";

// USERS
export const GET_USERS_LIST = "GET_USERS_LIST";
export const GET_USER = "GET_USER";
export const POST_USER = "POST_USER";
export const PUT_USER = "PUT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// IMAGES
export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const GET_IMAGE = "GET_IMAGE";
export const POST_IMAGE = "POST_IMAGE";
