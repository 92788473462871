import {
  faHome,
  faUsers,
  faBoxes,
  faStore,
  faBox,
  faInbox,
  faShip,
} from "@fortawesome/free-solid-svg-icons";

const TcsMenu = [
  {
    name: "Dashboard",
    path: "/home",
    icon: faHome,
  },
  {
    name: "Customers",
    icon: faUsers,
    submenu: [
      {
        name: "Customers",
        path: "/customers",
      },
      {
        name: "Customer categories",
        path: "/customer-categories",
      },
    ],
  },
  {
    name: "Suppliers",
    icon: faBoxes,
    submenu: [
      {
        name: "Suppliers",
        path: "/suppliers",
      },
      {
        name: "Supplier categories",
        path: "/supplier-categories",
      },
    ],
  },
  {
    name: "Stores",
    path: "/stores",
    icon: faStore,
  },
  {
    name: "Products",
    icon: faBox,
    submenu: [
      {
        name: "Products",
        path: "/products",
      },
      {
        name: "Product categories",
        path: "/product-categories",
      },
      {
        name: "Measurement units",
        path: "/measurement-units",
      },
    ],
  },
  {
    name: "Receptions",
    path: "/receptions",
    icon: faInbox,
  },
  {
    name: "Shipments",
    path: "/shipments",
    icon: faShip,
  },
  /* {
      name: "Support",
      path: "/support",
      icon: faQuestion,
    }, */
];

export default TcsMenu;
