import React, { Suspense, lazy, useEffect, useState } from "react"
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  matchPath,
} from "react-router-dom"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { useDispatch, useSelector } from "react-redux"

import IdleTimer from "./utils/IdleTimer"
/* Auth actions */
import { validateAuth } from "./actions/authActions"

/* loader component for Suspense */
import PageLoader from "./components/Common/PageLoader"

/* eslint-disable-next-line */
import Core from "./components/Core/Core"
/* eslint-disable-next-line */
import Bootstrap from "./components/Bootstrap/Bootstrap"
/* eslint-disable-next-line */
import Common from "./components/Common/Common"
/* eslint-disable-next-line */
import Colors from "./components/Colors/Colors"
/* eslint-disable-next-line */
import FloatButton from "./components/FloatButton/FloatButton"
/* eslint-disable-next-line */

import User from "./views/User/User"
import PrintProductBarcode from "./views/Products/PrintProductBarcode"

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />

const Home = lazy(() => import("./views/Home/Home"))

const Users = lazy(() => import("./views/Users/Users"))
const AddUser = lazy(() => import("./views/Users/AddUser"))
const EditUser = lazy(() => import("./views/Users/EditUser"))

const CustomerCategories = lazy(() =>
  import("./views/CustomerCategories/CustomerCategories")
)
const AddCustomerCategory = lazy(() =>
  import("./views/CustomerCategories/AddCustomerCategory")
)
const EditCustomerCategory = lazy(() =>
  import("./views/CustomerCategories/EditCustomerCategory")
)

const Customers = lazy(() => import("./views/Customers/Customers"))
const AddCustomer = lazy(() => import("./views/Customers/AddCustomer"))
const EditCustomer = lazy(() => import("./views/Customers/EditCustomer"))
const DeleteCustomer = lazy(() => import("./views/Customers/DeleteCustomer"))

const SupplierCategories = lazy(() =>
  import("./views/SupplierCategories/SupplierCategories")
)
const AddSupplierCategory = lazy(() =>
  import("./views/SupplierCategories/AddSupplierCategory")
)
const EditSupplierCategory = lazy(() =>
  import("./views/SupplierCategories/EditSupplierCategory")
)

const Suppliers = lazy(() => import("./views/Suppliers/Suppliers"))
const AddSupplier = lazy(() => import("./views/Suppliers/AddSupplier"))
const EditSupplier = lazy(() => import("./views/Suppliers/EditSupplier"))
const DeleteSupplier = lazy(() => import("./views/Suppliers/DeleteSupplier"))

const Stores = lazy(() => import("./views/Stores/Stores"))
const AddStore = lazy(() => import("./views/Stores/AddStore"))
const EditStore = lazy(() => import("./views/Stores/EditStore"))
const DeleteStore = lazy(() => import("./views/Stores/DeleteStore"))
const ProductsStore = lazy(() => import("./views/Stores/ProductsStore"))

const Products = lazy(() => import("./views/Products/Products"))
const AddProduct = lazy(() => import("./views/Products/AddProduct"))
const EditProduct = lazy(() => import("./views/Products/EditProduct"))
const DeleteProduct = lazy(() => import("./views/Products/DeleteProduct"))
const StoresProduct = lazy(() => import("./views/Products/StoresProduct"))

const ProductCategories = lazy(() =>
  import("./views/ProductCategories/ProductCategories")
)
const AddProductCategory = lazy(() =>
  import("./views/ProductCategories/AddProductCategory")
)
const EditProductCategory = lazy(() =>
  import("./views/ProductCategories/EditProductCategory")
)

const MeasurementUnits = lazy(() =>
  import("./views/MeasurementUnits/MeasurementUnits")
)
const AddMeasurementUnit = lazy(() =>
  import("./views/MeasurementUnits/AddMeasurementUnit")
)
const EditMeasurementUnit = lazy(() =>
  import("./views/MeasurementUnits/EditMeasurementUnit")
)

const Receptions = lazy(() => import("./views/Receptions/Receptions"))
const AddReception = lazy(() => import("./views/Receptions/AddReception"))
const EditReception = lazy(() => import("./views/Receptions/EditReception"))
const DeleteReception = lazy(() => import("./views/Receptions/DeleteReception"))
const ReceptionDetails = lazy(() =>
  import("./views/Receptions/ReceptionDetails")
)
const ProcessReception = lazy(() =>
  import("./views/Receptions/ProcessReception")
)

const Shipments = lazy(() => import("./views/Shipments/Shipments"))
const AddShipment = lazy(() => import("./views/Shipments/AddShipment"))
const EditShipment = lazy(() => import("./views/Shipments/EditShipment"))
const DeleteShipment = lazy(() => import("./views/Shipments/DeleteShipment"))
const ShipmentDetails = lazy(() => import("./views/Shipments/ShipmentDetails"))
const ProcessShipment = lazy(() => import("./views/Shipments/ProcessShipment"))

const Loans = lazy(() => import("./views/Loans/Loans"))
const AddLoan = lazy(() => import("./views/Loans/AddLoan"))
const EditLoan = lazy(() => import("./views/Loans/EditLoan"))
const DeleteLoan = lazy(() => import("./views/Loans/DeleteLoan"))
const LoanDetails = lazy(() => import("./views/Loans/LoanDetails"))
const ProcessLoan = lazy(() => import("./views/Loans/ProcessLoan"))

const Returnments = lazy(() => import("./views/Returnments/Returnments"))
const AddReturnment = lazy(() => import("./views/Returnments/AddReturnment"))
const EditReturnment = lazy(() => import("./views/Returnments/EditReturnment"))
const DeleteReturnment = lazy(() => import("./views/Returnments/DeleteReturnment"))
const ReturnmentDetails = lazy(() => import("./views/Returnments/ReturnmentDetails"))
const ProcessReturnment = lazy(() => import("./views/Returnments/ProcessReturnment"))

const SupplierReport = lazy(() => import("./views/Reports/SupplierReport"))
const CustomerReport = lazy(() => import("./views/Reports/CustomerReport"))
const StockReport = lazy(() => import("./views/Reports/StockReport"))
const MovementsReport = lazy(() => import("./views/Reports/MovementsReport"))
const LoanReport = lazy(() => import("./views/Reports/LoanReport"))

const ChangePassword = lazy(() => import("./views/Password/ChangePassword"))

const Support = lazy(() => import("./views/Support/Support"))

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/"
  const timeout = { enter: 500, exit: 500 }

  const animationName = "rag-fadeIn"

  const { isAuthenticated, authVerified, sessionClosed } = useSelector(
    (state) => {
      return state.auth
    }
  )

  /* const [isTimeout, setIsTimeout] = useState(false) */

  const languagesState = useSelector((state) => {
    return state.languages
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (!authVerified) dispatch(validateAuth())
  }, [authVerified, languagesState, dispatch])

  /* useEffect(() => {
    const timer = new IdleTimer({
      timeout: 2 * 60 * 60, //expire after 2 hours
      onTimeout: () => {
        setIsTimeout(true)
      },
      onExpired: () => {
        //do something if expired on load
        setIsTimeout(true)
      },
    })

    return () => {
      timer.cleanUp()
    }
  }, [])

  console.log(`Is timeout: ${isTimeout}`) */

  const match = matchPath(window.location.pathname, {
    path: "/products/barcode/:id",
    exact: true,
    strinct: false,
  })

  if (match?.isExact) {
    return (
      <Switch location={location}>
        <Route
          exact
          path="/products/barcode/:id"
          component={PrintProductBarcode}
        />
      </Switch>
    )
  }

  if (!authVerified) return <PageLoader />

  if (!isAuthenticated) {
    return (
      <Switch location={location}>
        <Route path="/login" exact component={User.Login} />
        <Route path="/recover" exact component={User.Recover} />
        <Route path="/activate/:key" exact component={User.Activate} />
        <Redirect to="/login" />
      </Switch>
    )
  } else {
    return (
      // Layout component wrapper
      <Core>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route path="/" exact component={waitFor(Home)} />
                  <Route exact path="/home" component={waitFor(Home)} />
                  <Route
                    exact
                    path="/change-password"
                    component={waitFor(ChangePassword)}
                  />
                  <Route exact path="/users" component={waitFor(Users)} />
                  <Route exact path="/users/add" component={waitFor(AddUser)} />
                  <Route
                    exact
                    path="/users/edit/:id"
                    component={waitFor(EditUser)}
                  />
                  <Route
                    exact
                    path="/customers"
                    component={waitFor(Customers)}
                  />
                  <Route
                    exact
                    path="/customers/add"
                    component={waitFor(AddCustomer)}
                  />
                  <Route
                    exact
                    path="/customers/edit/:id"
                    component={waitFor(EditCustomer)}
                  />
                  <Route
                    exact
                    path="/customers/delete/:id"
                    component={waitFor(DeleteCustomer)}
                  />
                  <Route
                    exact
                    path="/customer-categories"
                    component={waitFor(CustomerCategories)}
                  />
                  <Route
                    exact
                    path="/customer-categories/add"
                    component={waitFor(AddCustomerCategory)}
                  />
                  <Route
                    exact
                    path="/customer-categories/edit/:id"
                    component={waitFor(EditCustomerCategory)}
                  />
                  <Route
                    exact
                    path="/suppliers"
                    component={waitFor(Suppliers)}
                  />
                  <Route
                    exact
                    path="/suppliers/add"
                    component={waitFor(AddSupplier)}
                  />
                  <Route
                    exact
                    path="/suppliers/edit/:id"
                    component={waitFor(EditSupplier)}
                  />
                  <Route
                    exact
                    path="/suppliers/delete/:id"
                    component={waitFor(DeleteSupplier)}
                  />
                  <Route
                    exact
                    path="/supplier-categories"
                    component={waitFor(SupplierCategories)}
                  />
                  <Route
                    exact
                    path="/supplier-categories/add"
                    component={waitFor(AddSupplierCategory)}
                  />
                  <Route
                    exact
                    path="/supplier-categories/edit/:id"
                    component={waitFor(EditSupplierCategory)}
                  />
                  <Route exact path="/stores" component={waitFor(Stores)} />
                  <Route
                    exact
                    path="/stores/add"
                    component={waitFor(AddStore)}
                  />
                  <Route
                    exact
                    path="/stores/edit/:id"
                    component={waitFor(EditStore)}
                  />
                  <Route
                    exact
                    path="/stores/delete/:id"
                    component={waitFor(DeleteStore)}
                  />
                  <Route
                    exact
                    path="/stores/stock/:id"
                    component={waitFor(ProductsStore)}
                  />
                  <Route exact path="/products" component={waitFor(Products)} />
                  <Route
                    exact
                    path="/products/add"
                    component={waitFor(AddProduct)}
                  />
                  <Route
                    exact
                    path="/products/edit/:id"
                    component={waitFor(EditProduct)}
                  />
                  <Route
                    exact
                    path="/products/delete/:id"
                    component={waitFor(DeleteProduct)}
                  />
                  <Route
                    exact
                    path="/products/stock/:id"
                    component={waitFor(StoresProduct)}
                  />
                  <Route
                    exact
                    path="/product-categories"
                    component={waitFor(ProductCategories)}
                  />
                  <Route
                    exact
                    path="/product-categories/add"
                    component={waitFor(AddProductCategory)}
                  />
                  <Route
                    exact
                    path="/product-categories/edit/:id"
                    component={waitFor(EditProductCategory)}
                  />
                  <Route
                    exact
                    path="/measurement-units"
                    component={waitFor(MeasurementUnits)}
                  />
                  <Route
                    exact
                    path="/measurement-units/add"
                    component={waitFor(AddMeasurementUnit)}
                  />
                  <Route
                    exact
                    path="/measurement-units/edit/:id"
                    component={waitFor(EditMeasurementUnit)}
                  />
                  <Route
                    exact
                    path="/receptions"
                    component={waitFor(Receptions)}
                  />
                  <Route
                    exact
                    path="/receptions/add"
                    component={waitFor(AddReception)}
                  />
                  <Route
                    exact
                    path="/receptions/edit/:id"
                    component={waitFor(EditReception)}
                  />
                  <Route
                    exact
                    path="/receptions/details/:id"
                    component={waitFor(ReceptionDetails)}
                  />
                  <Route
                    exact
                    path="/receptions/process/:id"
                    component={waitFor(ProcessReception)}
                  />
                  <Route
                    exact
                    path="/receptions/delete/:id"
                    component={waitFor(DeleteReception)}
                  />
                  <Route
                    exact
                    path="/shipments"
                    component={waitFor(Shipments)}
                  />
                  <Route
                    exact
                    path="/shipments/add"
                    component={waitFor(AddShipment)}
                  />
                  <Route
                    exact
                    path="/shipments/edit/:id"
                    component={waitFor(EditShipment)}
                  />
                  <Route
                    exact
                    path="/shipments/delete/:id"
                    component={waitFor(DeleteShipment)}
                  />
                  <Route
                    exact
                    path="/shipments/details/:id"
                    component={waitFor(ShipmentDetails)}
                  />
                  <Route
                    exact
                    path="/shipments/process/:id"
                    component={waitFor(ProcessShipment)}
                  />
                  <Route exact path="/loans" component={waitFor(Loans)} />
                  <Route exact path="/loans/add" component={waitFor(AddLoan)} />
                  <Route
                    exact
                    path="/loans/edit/:id"
                    component={waitFor(EditLoan)}
                  />
                  <Route
                    exact
                    path="/loans/delete/:id"
                    component={waitFor(DeleteLoan)}
                  />
                  <Route
                    exact
                    path="/loans/details/:id"
                    component={waitFor(LoanDetails)}
                  />
                  <Route
                    exact
                    path="/loans/process/:id"
                    component={waitFor(ProcessLoan)}
                  />
                  <Route
                    exact
                    path="/returnments"
                    component={waitFor(Returnments)}
                  />
                  <Route
                    exact
                    path="/returnments/add"
                    component={waitFor(AddReturnment)}
                  />
                  <Route
                    exact
                    path="/returnments/edit/:id"
                    component={waitFor(EditReturnment)}
                  />
                  <Route
                    exact
                    path="/returnments/delete/:id"
                    component={waitFor(DeleteReturnment)}
                  />
                  <Route
                    exact
                    path="/returnments/details/:id"
                    component={waitFor(ReturnmentDetails)}
                  />
                  <Route
                    exact
                    path="/returnments/process/:id"
                    component={waitFor(ProcessReturnment)}
                  />
                  <Route
                    exact
                    path="/customer/report"
                    component={waitFor(CustomerReport)}
                  />
                  <Route
                    exact
                    path="/supplier/report"
                    component={waitFor(SupplierReport)}
                  />
                  <Route
                    exact
                    path="/loan/report"
                    component={waitFor(LoanReport)}
                  />
                  <Route
                    exact
                    path="/stock/report"
                    component={waitFor(StockReport)}
                  />
                  <Route
                    exact
                    path="/movement/report"
                    component={waitFor(MovementsReport)}
                  />
                  <Route exact path="/support" component={waitFor(Support)} />
                  <Redirect to="/home" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Core>
    )
  }
}

export default withRouter(Routes)
