import * as Sentry from "@sentry/react";
import {
  VALIDATION_FORM,
  LOGIN,
  LOGIN_SUCCESSFUL,
  LOGIN_ERROR,
  IS_AUTH,
  LOGOUT,
  REGISTER,
  PASSWORD_RESET,
  NOT_LOGGED,
} from "../types"

import Api from "../components/Api/Api"

export function signIn(email, password) {
  return async (dispatch) => {
    dispatch(login())

    let result = await Api.authenticate(email, password)
    if (!result.success) {
      dispatch(loginError(result.error))
      return
    }
    localStorage.setItem("token", result.token)
    localStorage.setItem("userId", result.userId)
    localStorage.setItem("firstName", result.firstName)
    localStorage.setItem("lastName", result.lastName)
    localStorage.setItem("email", result.email)
    localStorage.setItem("profileId", result.profile.id)
    localStorage.setItem("profile", result.profile.profileName)

    Sentry.setUser(result);

    dispatch(loggedIn(result))
  }
}

export function validateAuth() {
  return async (dispatch) => {
    let result = await Api.getAuthenticatedUser()
    if (result.success) {
      dispatch(loggedIn(result))
      return
    }
    dispatch(notLogged())
  }
}

export function signOut() {
  localStorage.clear()
  Sentry.configureScope(scope => scope.clear());
  return async (dispatch) => dispatch(logoff())
}

const validationLoginResult = (name, value, result) => ({
  type: VALIDATION_FORM,
  payload: {
    name: name,
    value: value,
    result: result,
  },
})

const login = () => ({ type: LOGIN })
export const loggedIn = (user) => ({ type: LOGIN_SUCCESSFUL, payload: user })
const loginError = (error) => ({ type: LOGIN_ERROR, payload: error })
const logoff = () => ({ type: LOGOUT })
const notLogged = () => ({ type: NOT_LOGGED })
