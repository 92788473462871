import { combineReducers } from "redux"
import { AuthReducer } from "./authReducer"
import { UsersReducer } from "./usersReducer"
import { ImagesReducer } from "./imagesReducer"

export default combineReducers({
  auth: AuthReducer,
  users: UsersReducer,
  images: ImagesReducer,
})
