import React from "react";
import { useSelector } from "react-redux";

import "./Core.scss";
import "./LayoutVariants.scss";

import Header from "../Header/Header";
import HeaderSearch from "../Header/HeaderSearch";
import Sidebar from "../Sidebar/Sidebar";
import Settings from "../Settings/Settings";

const Core = (props) => {
  const authState = useSelector((state) => state.auth);
  return (
    <div className="layout-container">
      <Header authState={authState} />

      <Sidebar authState={authState} />
      <div className="sidebar-layout-obfuscator"></div>

      {/* Page content */}
      <main className="main-container">
        {props.children}

        {/* Page footer */}
        <footer className="text-center">
          <span className="pr-4">&copy; 2020 Henkel</span>
          <span className="pr-4 pl-4">Terms of use</span>
          <span className="pr-4 pl-4">Data protection</span>
          <span className="pl-4">@EnterpriseOpen!</span>
        </footer>
      </main>

      {/* Search template */}
      <HeaderSearch />

      {/* Settings template */}
      <Settings />
    </div>
  );
};

export default Core;
