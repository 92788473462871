import {
  LOADING,
  GET_USERS_LIST,
  ERROR,
  GET_PROFILES_LIST,
  GET_ALL_LANGUAGES,
  GET_COUNTRIES_LIST,
  POST_USER,
  GET_USER,
  PUT_USER,
  ACTIVATION_KEY_ERROR,
  ACTIVATION_KEY_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET,
  CHANGE_PASSWORD,
  DATA_LOADED,
} from "../types";

const initialState = {
  search: "",
  loading: false,
  userLoaded: false,
  saved: false,
  passwordRequestDone: false,
  activationValidated: false,
  passwordResetDone: false,
  passwordChangeDone: false,
  error: "",
  activationKeyError: "",
  passwordResetError: "",
  usersList: [],
  profiles: [],
  countries: [],
  languages: [],
  user: {
    id: 0,
  },
};

export function UsersReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        userLoaded: false,
        saved: false,
        passwordRequestDone: false,
        activationValidated: false,
        passwordResetDone: false,
        passwordChangeDone: false,
        error: "",
      };
    case PASSWORD_RESET: {
      return { ...state, loading: false, error: "", passwordRequestDone: true };
    }
    case CHANGE_PASSWORD: {
      return { ...state, loading: false, error: "", passwordChangeDone: true };
    }
    case ACTIVATION_KEY_ERROR: {
      return {
        ...state,
        user: { id: 0 },
        activationKeyError: action.payload,
        loading: false,
        activationValidated: true,
      };
    }
    case ACTIVATION_KEY_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        activationKeyError: "",
        loading: false,
        activationValidated: true,
      };
    }
    case PASSWORD_RESET_ERROR: {
      return {
        ...state,
        passwordResetError: action.payload,
        loading: false,
        passwordResetDone: true,
      };
    }
    case PASSWORD_RESET_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        passwordResetError: "",
        loading: false,
        passwordResetDone: true,
      };
    }
    case GET_USERS_LIST:
      return {
        ...state,
        loading: false,
        error: "",
        usersList: action.payload,
        user: { id: 0 },
      };
    case GET_PROFILES_LIST:
      return {
        ...state,
        loading: false,
        error: "",
        profiles: action.payload,
      };
    case GET_ALL_LANGUAGES:
      return {
        ...state,
        loading: false,
        error: "",
        languages: action.payload,
      };
    case GET_COUNTRIES_LIST:
      return {
        ...state,
        loading: false,
        error: "",
        countries: action.payload,
      };
    case POST_USER:
      return {
        ...state,
        loading: false,
        saved: true,
        error: "",
        user: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        loading: false,
        userLoaded: true,
        error: "",
        user: action.payload,
      };
    case PUT_USER:
      return {
        ...state,
        loading: false,
        userLoaded: false,
        saved: true,
        error: "",
        user: action.payload,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        saved: false,
        userLoaded: false,
        passwordRequestDone: false,
        activationValidated: false,
        passwordResetDone: false,
        passwordChangeDone: false,
        error: action.payload,
      };
    case DATA_LOADED:
      return {
        ...state,
        userLoaded: false,
        saved: false,
        loading: false,
        passwordRequestDone: false,
        activationValidated: false,
        passwordResetDone: false,
        passwordChangeDone: false,
      };
    default:
      return state;
  }
}
