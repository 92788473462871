import {
  LOADING,
  GET_ALL_IMAGES,
  GET_IMAGE,
  POST_IMAGE,
  ERROR,
  DATA_LOADED,
} from "../types";

const initialState = {
  search: "",
  loading: false,
  registerDone: false,
  getDone: false,
  error: "",
  imagesList: [],
  image: {},
};

export function ImagesReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        registerDone: false,
        getDone: false,
      };
    case GET_ALL_IMAGES:
      return {
        ...state,
        loading: false,
        error: "",
        imagesList: action.payload,
      };
    case GET_IMAGE:
      return {
        ...state,
        loading: false,
        getDone: true,
        error: "",
        image: action.payload,
      };
    case POST_IMAGE:
      return {
        ...state,
        loading: false,
        error: "",
        registerDone: true,
        image: action.payload,
      };
    case DATA_LOADED:
      return {
        ...state,
        loading: false,
        registerDone: false,
        getDone: false,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        registerDone: false,
        getDone: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
