import React, { useEffect, useState } from "react";

const Layout = (props) => (
  <div className="layout-container">
    <div className="page-container">
      {/* Page content */}
      {props.children}
    </div>
  </div>
);

export default Layout;
