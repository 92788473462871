import Api from "../components/Api/Api"
import {
  LOADING,
  ERROR,
  GET_USERS_LIST,
  GET_PROFILES_LIST,
  GET_COUNTRIES_LIST,
  GET_ALL_LANGUAGES,
  POST_USER,
  PUT_USER,
  GET_USER,
  ACTIVATION_KEY_ERROR,
  ACTIVATION_KEY_SUCCESS,
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  CHANGE_PASSWORD,
  DATA_LOADED,
} from "../types/index"

export const putEmailForActivationKey = (email) => {
  return async (dispatch) => {
    let result = await Api.requestActivationKey(email)
    if (!result.success) {
      dispatch(error(result.error))
      return
    }
    dispatch(putEmailForActivationKeySuccess())
  }
}

export function validateActivationKey(key) {
  return async (dispatch) => {
    dispatch(loading())
    let result = await Api.getUserFromActivationKey(key)
    if (!result.success) {
      dispatch(validateActivationKeyError(result.error))
      return
    }

    dispatch(validateActivationKeySuccess(result.user))
  }
}

export function putPasswordReset(key, password, repeatPassword) {
  return async (dispatch) => {
    dispatch(loading())
    let result = await Api.resetPassword(key, password, repeatPassword)
    if (!result.success) {
      dispatch(putPasswordError(result.error))
      return
    }

    dispatch(putPasswordSuccess(result.user))
  }
}

export function getAllUsers() {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.getUsers()
    console.log(result)
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(getAllUsersSuccess(result.usersList))
  }
}

export function getAllProfiles() {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.getProfiles()
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(getAllProfilesSuccess(result.profiles))
  }
}

export function getAllCountries() {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.getCountries()
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(getAllCountriesSuccess(result.countries))
  }
}

export function getAllLanguages() {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.getLanguages()
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(getAllLanguagesSuccess(result.languages))
  }
}

export function newUser(user) {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.registerUser(user)
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(newUserSuccess(result.user))
  }
}

export function getUser(id) {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.getUser(id)
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(getUserSuccess(result.user))
  }
}

export function editUser(id, user) {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.updateUser(id, user)
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(editUserSuccess(result.user))
  }
}

export function changePassword(password, newPassword, repeatNewPassword) {
  return async (dispatch) => {
    dispatch(loading())

    const result = await Api.changePassword(
      password,
      newPassword,
      repeatNewPassword
    )
    if (!result.success) {
      dispatch(error(result.error))
      return
    }

    dispatch(changePasswordSuccess())
  }
}

export function DataLoaded() {
  return {
    type: DATA_LOADED,
  }
}

const loading = () => ({
  type: LOADING,
})

const putEmailForActivationKeySuccess = () => ({
  type: PASSWORD_RESET,
})

const validateActivationKeyError = (result) => ({
  type: ACTIVATION_KEY_ERROR,
  payload: result,
})

const validateActivationKeySuccess = (result) => ({
  type: ACTIVATION_KEY_SUCCESS,
  payload: result,
})

const putPasswordError = (result) => ({
  type: PASSWORD_RESET_ERROR,
  payload: result,
})

const putPasswordSuccess = (result) => ({
  type: PASSWORD_RESET_SUCCESS,
  payload: result,
})

const getAllUsersSuccess = (result) => ({
  type: GET_USERS_LIST,
  payload: result,
})

const getAllProfilesSuccess = (result) => ({
  type: GET_PROFILES_LIST,
  payload: result,
})

const getAllCountriesSuccess = (result) => ({
  type: GET_COUNTRIES_LIST,
  payload: result,
})

const getAllLanguagesSuccess = (result) => ({
  type: GET_ALL_LANGUAGES,
  payload: result,
})

const getUserSuccess = (result) => ({
  type: GET_USER,
  payload: result,
})

const newUserSuccess = (result) => ({
  type: POST_USER,
  payload: result,
})

const editUserSuccess = (result) => ({
  type: PUT_USER,
  payload: result,
})

const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD,
})

const error = (error) => ({ type: ERROR, payload: error })
