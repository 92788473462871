import "core-js/es/string"
import "core-js/es/array"
import "core-js/es/map"
import "core-js/es/set"
import "core-js/es/object"
import "core-js/es/promise"
import "core-js/es/object"
import "raf/polyfill"
import { ApolloProvider } from "react-apollo"
import { createHttpLink } from "apollo-link-http"
import { setContext } from "apollo-link-context"
import { InMemoryCache } from "apollo-cache-inmemory"
import { ApolloClient } from "apollo-boost"

import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App"
import "./i18n"
import { BASE_URL } from "./config/constants"

Sentry.init({
  dsn: "https://57230c387b5646a9981da8f0f281c06c@o560062.ingest.sentry.io/5695366",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.ENVIRONMENT
});

const httpLink = createHttpLink({
  uri: `${BASE_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token")
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("app")
)
