import React from "react"
import { useBarcode } from "react-barcodes"

const PrintProductBarcode = (props) => {
  const id = props.match.params.id

  const { inputRef } = useBarcode({
    value: id,
    text: id,
    options: {
      background: "#ffffff",
      displayValue: true,
      format: "CODE128",
      margin: 10,
      fontSize: 16,
      width: 3,
      textPosition: "top",
    },
  })

  const result = (
    <div>
      <img
        style={{ width: "2in", height: "1in" }}
        alt={id}
        ref={inputRef}
      />
    </div>
  ) 
    
  return result;
}

export default PrintProductBarcode
