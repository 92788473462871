import React from "react";

const Footer = (props) => {
  const fromLogin = props.fromLogin ?? false;
  return fromLogin ? (
    <footer className="fixed-bottom mt-4">
      <div className="row footer">
        <div className="col-12 text-center">
          <span className="pl-4 pr-4 text-white">&copy; 2020 Henkel</span>
          <span className="pl-4 pr-4 text-white">Terms of use</span>
          <span className="pl-4 pr-4 text-white">Data protection</span>
          <span className="pl-4 pr-4 text-white">@EnterpriseOpen!</span>
        </div>
      </div>
    </footer>
  ) : (
    <div></div>
  );
};

export default Footer;
