import i18n from "i18next";
import Backend from "i18next-xhr-backend";
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "es",
    fallbackLng: ["es", "en"],
    backend: {
      loadPath: "locales/{{lng}}/{{ns}}.json",
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    debug: true,
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
